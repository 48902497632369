<template>
    <div>
        <div class="container">
            <pre>
                {{detail.content}}
            </pre>
        </div>        
    </div>
</template>

<script>
import { eventBus } from '../../main'
export default {
    data(){
        return{
            idx: this.$route.params.idx,
            detail: {},
            goPath:'/agreement',
        }
    },
    mounted() {
        eventBus.goPath(this.goPath)
        this.termDetail();
    },
    props:{
    },
    methods: {
        termDetail() {
            eventBus.pageTitle(this.detail.title + " ("+ this.EssentialString(this.detail.essential) +")");
        },
        EssentialString(string){
            if(string == 1) return "필수"
            else return "선택"
        },
    },
}
</script>

<style>

</style>