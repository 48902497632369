<template>
    <div class="agreement">
        <Header :title="title"></Header>
        <div class="container">
            <h3 class="fw-bold mb-4">
                마이파킹 서비스 이용을 위해<br>아래 이용약관에 동의해주세요.
            </h3>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="allTermChk" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    모든 약관에 동의
                </label>
            </div>
            <hr>
            <div class="p-1">
                <div class="form-check w-100 d-flex justify-content-between align-items-center mb-3" v-for="(item, index) in terms" :key="index">
                    <div class="d-flex align-items-center">
                        <input class="form-check-input me-2" type="checkbox" v-model="termsChk" :value="item.no" :id="item.no">
                        <label class="form-check-label fs-sm" :for="item.no">
                            {{item.title}} ({{EssentialString(item.essential)}})
                        </label>
                    </div>
                    <i class="far fa-chevron-right fs-sm"  @click="$router.push({path:`agreement/${item.no}`})"></i>
                </div>
            </div>
        </div>
        
        <div class="footer_btn bg-white">
            <button class="btn btn-primary w-100" @click="AgreementClick">다음</button>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title:'가입 약관',
            allCheck:false,
            terms:[
                {
                    no:0,
                    title:'개인정보취급 처리방침',
                    essential:true
                },
                {
                    no:1,
                    title:'사이트 이용약관',
                    essential:true
                },
                {
                    no:2,
                    title:'광고 및 홍보 활용 수집 동의',
                    essential:false
                },
            ],
            consentTerms:[],
            choiceTerms:[],
            termsChk: [],
            goPath:'/main/misa',
        }
    },
    computed: {
        allTermChk: {
            get: function(){
                if( this.termsChk.length != this.terms.length ){
                    return false
                }else{
                    return true
                }
            },
            set: function(e){
                if( e ){
                    let _list = [];
                    for( var i=0; i<this.terms.length; i++ ){
                        _list.push(this.terms[i].no);
                    }
                    this.termsChk = _list;
                }else{
                    this.termsChk = [];
                }
            }
        }

    },
    methods:{
        EssentialString(string){
            if(string == 1) return "필수"
            else return "선택"
        },
        AgreementClick() {
            const ChoiceArr = [];
            if(this.consentTerms.length > this.termsChk) return this.$alert("필수 이용약관을 선택해주세요.");

            for(var i=0; i<this.consentTerms.length; i++){
                var count = 0;
                for(var j=0; j<this.termsChk.length; j++){
                    if(this.consentTerms[i] == this.termsChk[j]) count++;
                }
                if(count == 0) return this.$alert("필수 이용약관을 선택해주세요.");
            }
            
            var ChoiceNum = '';
            if(this.choiceTerms.length > 0){
                for(var i=0; i<this.choiceTerms.length; i++){
                    var count =0;
                    for(var j=0; j<this.termsChk.length; j++){
                        if(this.choiceTerms[i] == this.termsChk[j]) count++;
                    }
                    if(count > 0) ChoiceArr.push(this.choiceTerms[i]);
                }
                ChoiceNum = ChoiceArr.join(',');
            }
            this.$router.push('/sign/signin');
        },
    },
    created(){
        eventBus.goPath(this.goPath);
    }
}
</script>

<style lang="scss" scoped>

</style>